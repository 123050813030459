import styled from '@emotion/styled'

import { BlockTitle, Container } from 'components/common/styles'
import SmartInputField from 'components/forms/smart-input-field'
import SmartNumberInputField from 'components/forms/smart-number-input-field'
import SmartCheckboxField from '../../../forms/smart-checkbox-field'

export const LocalContainer = styled(Container)`
  padding: 120px 0 100px 0;
  margin: 0 auto;
  max-width: 650px;
  
  @media screen and (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

export const Title = styled(BlockTitle)`
  margin-bottom: 30px;
`

export const SubTitle = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #646363;
  margin-top: 15px;
  margin-bottom: 30px;
`

export const Input = styled(SmartInputField)`
  margin-bottom: 32px;
`

export const Number = styled(SmartNumberInputField)`
  margin-bottom: 32px;
`

export const Checkboxes = styled.div`
  margin-bottom: 16px;
`

export const Checkbox = styled(SmartCheckboxField)`
  margin-bottom: 16px;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 32px;
  
  @media screen and (max-width: 768px) {
    align-items: center;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  
  & > :first-child {
    margin-right: 13px;
  }
`
