import React from 'react'

import SmartForm from 'components/forms/smart-form'
import { ValidationType } from 'components/forms/validations'
import Button, { ButtonSize } from 'components/common/button'

import { Actions, Checkbox, Checkboxes, Input, LocalContainer, Number, Title } from './styles'
import { Link } from 'gatsby'
import { default as axios } from 'axios'
import styled from "@emotion/styled"
import SentSVG from 'assets/images/sent.svg'

const Sent = styled.div`text-align: center;`
const SentTitle = styled.div`font-size: 34px;line-height: 1;font-weight: bold;color: #000000;margin-top: 60px;`
const SentSubTitle = styled.div`font-size: 14px;line-height: 1.5;color: #646363;margin-top: 25px;`

const FormSection = () => {
  const [isSending, setSending] = React.useState(false)
  const [isSent, setSent] = React.useState(false)

  return (
    <LocalContainer>
      <SmartForm
        onSubmit={(e, valid, fields, form) => {
          e.preventDefault()
          e.stopPropagation()

          if (!valid || isSending) return

          setSending(true)
          axios.post(`${process.env.GATSBY_WEBSITE_API}/contact`, {
            name: fields.name.value,
            email: fields.email.value,
            phone: fields.phone.value,
            message: fields.message.value,
            newsletter: fields.newsletter.value,
          }).then(() => {
            form.resetForm()
            setSending(false)
            setSent(true)
            window.analytics.track("Submitted contact form", {})
          }).catch(e => {
            setSending(false)
            alert('Error while sending request. Try again later.')
            window.analytics.track("Error while submitting contact form", {})
          })
        }}
      >
        {!isSent && (
          <>
            <Title>Got questions? We're here to help.</Title>

            <Input name="name" label="Name" validations={[{ type: ValidationType.REQUIRED }]} />
            <Number
              name="phone"
              label="Phone Number"
              type="tel"
              format="(###) ###-####"
              mask="_"
              validations={[{ type: ValidationType.REQUIRED }]}
            />
            <Input
              name="email"
              label="Email"
              validations={[{ type: ValidationType.REQUIRED }, { type: ValidationType.EMAIL }]}
            />
            <Input name="message" label="Message" validations={[{ type: ValidationType.REQUIRED }]} />
            <Checkboxes>
              <Checkbox name="newsletter" label="Subscribe to ComCard newsletter." defaultValue={true} />
              <Checkbox
                name="privacy"
                label={<>I read and understand ComCard's <Link to="/legal/privacy-policy">Privacy Policy</Link>.</>}
                validations={[{ type: ValidationType.CHECKBOX }]}
              />
            </Checkboxes>
            <Actions>
              <Button loading={isSending} size={ButtonSize.MEDIUM}>Contact me</Button>
            </Actions>
          </>
        )}
        {isSent && (
          <Sent>
            <img src={SentSVG} alt="Sent" />
            <SentTitle>Thank you!</SentTitle>
            <SentSubTitle>We received your request and our manager will contact you soon.</SentSubTitle>
          </Sent>
        )}
      </SmartForm>
    </LocalContainer>
  )
}

export default FormSection
