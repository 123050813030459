import React from 'react'

import { ContactEmail, ContactPhone, Container, Content, ItemContainer, SubTitle, Title } from './styles'

const ContactSection = () => (
  <Container>
    <Title>Hola! Contact us</Title>
    <Content>
      <ItemContainer>
        <SubTitle>Customer Support</SubTitle>
        <ContactPhone href="tel:+18449030903">(844) 903 - 0903</ContactPhone>
        <ContactEmail href="mailto:support@comcard.us">support@comcard.us</ContactEmail>
      </ItemContainer>
      <ItemContainer>
        <SubTitle>Merchants Sales</SubTitle>
        <ContactPhone href="tel:+18449030903">(844) 903 - 0903</ContactPhone>
        <ContactEmail href="mailto:merchant@comcard.us">merchant@comcard.us</ContactEmail>
      </ItemContainer>
    </Content>
  </Container>
)

export default ContactSection
