import styled from '@emotion/styled'

export const Title = styled.h2`
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  
  @media screen and (max-width: 900px) {
    font-size: 34px;
    line-height: 50px;
  }
`

export const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 64px;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  width: 70%;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const SubTitle = styled.h4`
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:nth-of-type(2) {
    margin: 0 20px;
  }
  
  @media screen and (max-width: 768px) {
    margin-top: 24px;
    
    :first-of-type {
      margin-top: 40px;
    }
    
     &:nth-of-type(2) {
      margin: 24px 0 0 0;
  }
    
  }
`

export const ContactPhone = styled.a`
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  text-decoration: none;
  margin: 4px 0;
`

export const ContactEmail = styled(ContactPhone)`
  color: #666668;
`
