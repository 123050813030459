import React from 'react'
import Layout from 'layouts/default'

import { Container } from 'components/common/styles'

import ContactSection from 'components/pages/contact-us/contact'
import FormSection from 'components/pages/contact-us/form'
import richSnippet from 'components/common/rich-snippet'
import { createContactUsSchema } from 'utils/richSnippets'


const ContactUsPage = () => {
  React.useEffect(() => {
    window.analytics.page('Contact Us')
  }, [])
  return (
    <Layout title="Contact us | ComCard" helmetChildren={richSnippet(createContactUsSchema())}>
      <Container>
        <ContactSection />
        <FormSection />
      </Container>
    </Layout>
  )
}

export default ContactUsPage
